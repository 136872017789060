import BaseMap from '../utils/BaseMap';

export default class AnalysisShow extends BaseMap {
  constructor() {
    super();
  }

  onRectangleClick(gridItem) {
    $.ajax({
      url: $("#map").data("cell-infos-url"),
      data: { bounds: gridItem.bounds },
      success: (data) => {
        $("#cell-info-modal").modal("show");
        $("#cell-info-modal .modal-body").html(data);
      }
    });
  }
}
