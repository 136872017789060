import ConfigRoutes from "../../config/Routes"
import DashboardIndex from "../controllers/DashboardIndex";
import PermissionsIndex from "../controllers/PermissionsIndex";
import AdminsIndex from "../controllers/AdminsIndex";
import AdminsEdit from "../controllers/AdminsEdit";
import AdminsNew from "../controllers/AdminsNew";
import RoleIndex from "../controllers/RoleIndex";
import RoleEdit from "../controllers/RoleEdit";
import ProfileIndex from "../controllers/ProfileIndex";
import AdminMenuIndex from "../controllers/AdminMenuIndex";
import AdminMenuForm from "../controllers/AdminMenuForm";
import CampaignsShow from "../controllers/CampaignsShow";
import CampaignsActionMap from "../controllers/CampaignsActionMap";

import UsersNew from "../controllers/UsersNew";
import UsersEdit from "../controllers/UsersEdit";

import SimulatedDevicesIndex from "../controllers/SimulatedDevicesIndex";
import SelectorsShow from "../controllers/SelectorsShow";

import AnalysisShow from "../controllers/AnalysisShow";


export default class Routes extends ConfigRoutes{

    static routes = {
        "DashboardIndex": DashboardIndex,
        "PermissionsIndex": PermissionsIndex,
        "AdminsIndex": AdminsIndex,
        "AdminsEdit": AdminsEdit,
        "AdminsUpdate": AdminsEdit,
        "AdminsNew": AdminsNew,
        "AdminsCreate": AdminsNew,
        "RolesIndex": RoleIndex,
        "RolesEdit": RoleEdit,
        "RolesNew": RoleEdit,
        "RolesUpdate": RoleEdit,
        "RolesCreate": RoleEdit,
        "ProfileIndex": ProfileIndex,
        "AdminMenusIndex": AdminMenuIndex,
        "AdminMenusNew": AdminMenuForm,
        "AdminMenusCreate": AdminMenuForm,
        "AdminMenusEdit": AdminMenuForm,
        "AdminMenusUpdate": AdminMenuForm,
        "CampaignsShow": CampaignsShow,
        "UsersEdit": UsersEdit,
        "UsersNew": UsersNew,
        "CampaignsActionMap": CampaignsActionMap,
        "SimulatedDevicesIndex": SimulatedDevicesIndex,
        "SelectorsShow": SelectorsShow,
        "AnalysisShow": AnalysisShow
    };

    constructor() {
        super();
        this.routes = Object.assign(this.routes, Routes.routes);
    }
}