import ApexCharts from 'apexcharts';

export default class DashboardIndex {
    constructor() {
        this.loader_html = '<div style="position: absolute; top:50%; left:50%;"><span class="loader"></span></div>';
        this.bindEvents();
    }

    bindEvents() {
        this.loadChart("#proxies-chart", "line", {
            title: "Taux de réussite des proxies actifs",
            yaxisTitle: "Pourcentage de succès",
            yaxisFormatter: val => `${Math.round(val)}%`,
            tooltipFormatter: val => `${Math.round(val)}%`
        });

        this.loadChart("#proxies-repartition-chart", "pie", {
            title: "Répartition des proxies actifs",
            tooltipFormatter: val => `${val} utilisations`
        });

        this.loadChart("#simulated-devices-chart-desktop", "line", {
            title: "🖥️ Taux de réussite des appareils desktop simulés",
            yaxisTitle: "Pourcentage de succès",
            yaxisFormatter: val => `${Math.round(val)}%`,
            tooltipFormatter: val => `${Math.round(val)}%`
        });

        this.loadChart("#simulated-devices-chart-mobile", "line", {
          title: "📱 Taux de réussite des appareils mobile simulés",
          yaxisTitle: "Pourcentage de succès",
          yaxisFormatter: val => `${Math.round(val)}%`,
          tooltipFormatter: val => `${Math.round(val)}%`
      });

        this.loadChart("#simulated-devices-repartition-chart", "pie", {
            title: "Répartition des appareils simulés",
            tooltipFormatter: val => `${val} utilisations`
        });

        // for each .win-rate-chart
        $('.win-rate-chart').each((index, element) => {
            this.loadWinRateChart(`#${element.id}`, element.dataset.title);
        });
    }

    loadWinRateChart(chartElementId, title) {
      if ($(chartElementId).children().length < 1) {
        const url = $(chartElementId).data('url');

        $(chartElementId).html(this.loader_html);

        $.ajax({
          url: url,
          type: 'GET',
          success: function (data) {
            $(chartElementId).empty();

            const options = {
                series: [{
                  name: title,
                  data: data.data
                }],
                chart: {
                  type: 'line',
                  height: 300,
                  toolbar: {
                      show: false
                  },
                  zoom: {
                      enabled: false
                  }
                },
                dataLabels: {
                  enabled: false
                },
                stroke: {
                    curve: 'smooth',
                    width: 2
                },
                    xaxis: {
                        type: 'category',
                        categories: data.labels,
                        labels: {
                          rotate: -45
                        }
                    },
                    yaxis: {
                        min: 0,
                        max: 100,
                        title: {
                            text: 'Taux de réussite (%)'
                        },
                        labels: {
                            formatter: function (value) {
                                return `${Math.round(value)}%`;
                            }
                        },
                        tickAmount: 4
                    },
                    tooltip: {
                        x: {
                            format: 'dd/MM/yyyy'
                        },
                        y: {
                            formatter: function (value) {
                                return `${Math.round(value)}%`;
                            }
                        }
                    },
                };

                const chart = new ApexCharts(document.querySelector(chartElementId), options);
                chart.render();
          }
      });
    }}

    loadChart(chartElementId, chartType, options) {
        if ($(chartElementId).children().length < 1) {
            const url = $(chartElementId).data('url');

            $(chartElementId).html(this.loader_html);

            $.ajax({
                url: url,
                type: 'GET',
                success: function (data) {
                    $(chartElementId).empty();
                    const commonOptions = {
                        series: data.series,
                        chart: {
                            type: chartType,
                            height: chartType === 'pie' ? 400 : 350,
                            animations: {
                                enabled: chartType === 'pie',
                                easing: 'easeinout',
                            },
                            toolbar: {
                                show: false
                            },
                            zoom: {
                                enabled: false
                            }
                        },
                        title: {
                            text: options.title,
                            align: 'center'
                        },
                        legend: {
                            position: 'bottom',
                            horizontalAlign: 'center',
                        }
                    };

                    const specificOptions = chartType === 'line' ? {
                        xaxis: {
                            categories: data.labels,
                            labels: {
                              rotate: -45
                            }
                        },
                        yaxis: {
                            min: 0,
                            max: 100,
                            title: {
                                text: options.yaxisTitle
                            },
                            labels: {
                                formatter: options.yaxisFormatter
                            },
                            tickAmount: 4
                        },
                        stroke: {
                            curve: 'smooth',
                            width: 2
                        },
                        markers: {
                            size: 4
                        },
                        tooltip: {
                            y: {
                                formatter: options.tooltipFormatter
                            },
                            x: {
                                format: 'dd/MM'
                            }
                        }
                    } : {
                        labels: data.labels,
                        responsive: [{
                            breakpoint: 480,
                            options: {
                                chart: {
                                    width: 200
                                }
                            }
                        }]
                    };

                    const finalOptions = { ...commonOptions, ...specificOptions };

                    const chart = new ApexCharts(document.querySelector(chartElementId), finalOptions);
                    chart.render();
                }
            });
        }
    }

    onDataChanged(data) {
        // Implement data handling logic here if needed
    }

    onDestroy() {
    }
}
