export default class SelectorsShow {

  constructor() {
      this.bindEvents();
      //window.application.setOnDataChangeListener(this);
  }


  bindEvents() {
    $(".selectors-datatable").DataTable({
      "paging": false,
      "info": false,
      "searching": false,
      "ordering": true,
    });

    $("#download-xpath-yaml").on("click", function() {
      $("#download-modal").modal("hide");
    });
  }

  onDataChanged(data) {
  }

  onDestroy() {
    $("#selectors-datatable").DataTable().destroy();
  }

}