import ApexCharts from 'apexcharts';
import { t } from 'i18n-js';

export default class CampaignsShow {

  constructor() {
      this.bindEvents();
  }


  bindEvents() {

    if (document.readyState !== 'loading') {
      this.onPageLoad();
    } else {
      document.addEventListener('DOMContentLoaded', function () {
        this.onPageLoad();
      });
    }
  }

  onPageLoad() {
    console.log("Page loaded")
  }

  onDataChanged(data) {
  }

  onDestroy() {
  }
}