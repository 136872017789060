

import ApexCharts from 'apexcharts';
export default class SimulatedDevicesIndex {

    constructor() {
        this.bindEvents();
        
    }


    bindEvents() {
      $("#form-desktop").removeClass("d-none");

      $("#selector-type").on("change", function(){
        if($(this).val() == "desktop"){
          $("#form-desktop").removeClass("d-none");
          $("#form-mobile").addClass("d-none");
        }else{
          $("#form-desktop").addClass("d-none");
          $("#form-mobile").removeClass("d-none");
        }
      });
    }

    onDataChanged(data) {
    }

    onDestroy() {
      // destroy the chart
      
    }
}