import UsersForm from "../utils/UsersForm";

export default class AdminsNew {

    constructor() {
        this.usersForm = new UsersForm();
        this.bindEvents();
    }


    bindEvents() {
    }

    onDataChanged(data) {
    }

    onDestroy() {
        this.usersForm.onDestroy();
    }
}