import BaseMap from '../utils/BaseMap';
import ApexCharts from 'apexcharts';

export default class CampaignsActionMap extends BaseMap {
  constructor() {
    super(); // Calls the constructor of the BaseMap class
  }

  // Override or extend methods as needed
  bindEvents() {
    super.bindEvents(); // Call the parent class's method to initialize common events
    this.initializeChart(); // Add specific event bindings for CampaignsActionMap
    this.bindAdditionalUIEvents(); // Additional UI events specific to CampaignsActionMap
  }

  initializeChart() {
    const chartElement = document.querySelector("#cell-activity-chart");

    if (!chartElement || chartElement.children.length > 0) return;

    const options = this.getChartOptions();
    this.chart = new ApexCharts(chartElement, options);
    this.chart.render();
  }

  getChartOptions() {
    return {
      series: [{ name: 'position', data: [] }],
      chart: {
        height: 350,
        type: 'area',
        toolbar: { show: false },
        zoom: { enabled: false }
      },
      stroke: { width: 1, curve: 'smooth' },
      xaxis: { type: 'category', categories: [] },
      yaxis: { reversed: true },
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'light',
          type: 'vertical',
          shadeIntensity: 0.5,
          gradientToColors: ["#FF0000"],
          inverseColors: false,
          opacityFrom: 0.2,
          opacityTo: 0.7,
          stops: [0, 100]
        }
      },
      tooltip: {
        y: {
          formatter: (value, { seriesIndex, dataPointIndex, w }) => {
            const prevValue = dataPointIndex > 0 ? w.globals.series[seriesIndex][dataPointIndex - 1] : null;
            const icon = prevValue !== null ? (value > prevValue ? 'mdi-arrow-down' : 'mdi-arrow-up') : '';
            return `<i class="mdi ${icon}"></i> ${value}`;
          }
        }
      }
    };
  }

  onRectangleClick(gridItem) {
    $.ajax({
      url: $("#map").data("cell-infos-url"),
      data: { bounds: gridItem.bounds },
      success: (data) => this.updateChartWithGridData(data)
    });
  }

  updateChartWithGridData(data) {
    const stats = data.data;

    this.chart.updateSeries([{ name: 'position', data: stats }]);
    this.chart.updateOptions({ xaxis: { categories: data.dates } });

    $("#zero-alert").toggleClass("d-none", !stats.includes(0));
    $("#grid-activity-modal").modal("show");
  }

  bindAdditionalUIEvents() {
    this.bindDateFilterModal();
    this.bindDateFilterSubmit();
    this.bindClearButton();
    this.bindActivityModal();
  }

  bindDateFilterModal() {
    $('#date_filter_modal').on('hidden.bs.modal', function () {
      $(this).find('form')[0].reset();
    });
  }

  bindDateFilterSubmit() {
    $("#date_filter_submit").on("click", function () {
      const startDate = $("#start_date");
      const endDate = $("#end_date");

      if (startDate.val() > endDate.val()) {
        startDate.addClass("is-invalid");
        endDate.addClass("is-invalid");
        return false;
      } else {
        startDate.removeClass("is-invalid");
        endDate.removeClass("is-invalid");
        $("#date_filter_form").submit();
        return true;
      }
    });
  }

  bindClearButton() {
    $("#clear_button").on("click", () => {
      $("#start_date").val("");
      $("#end_date").val("");
    });
  }

  bindActivityModal() {
    $("#grid-activity-modal").on("hidden.bs.modal", () => {
      $("#cell-activity-chart").html("");
    });
  }

  bindMarkerClickEvents() {
    $(".custom-marker").on("click", function () {
      const id = $(this).find(".marker-score").data("id");

      $.ajax({
        url: $("#map").data("activity-url"),
        data: { activity_id: id },
        success: (data) => {
          $("#activity-modal-content").html(data);
          $("#activity-modal").modal("show");
        }
      });
    });
  }
}
