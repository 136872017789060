import ApexCharts from 'apexcharts';

export default class BaseMap {
  constructor() {
    this.chart = null;
    this.map = null;
    this.defaultPosition = $("#map").data("default-position");
    this.bindEvents();
    this.startAutoRefresh();
  }

  bindEvents() {
    this.initializeMap();
    this.bindGridData();
    this.bindActionMarkers();
    this.bindUIEvents();
  }

  initializeMap() {
    this.map = L.map('map').setView(this.defaultPosition, 16);

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(this.map);

    this.addMarker(this.defaultPosition, '/leaflet/marker-icon-2x-red.png');
  }

  addMarker(position, iconUrl) {
    const defaultIcon = L.icon({
      iconUrl,
      iconSize: [25, 41],
      iconAnchor: [12, 41],
      shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
      shadowSize: [41, 41]
    });

    L.marker(position, { icon: defaultIcon }).addTo(this.map);
  }

  bindGridData() {
    this.loadGridData((gridData) => {
      gridData.forEach((item) => {
        item.forEach((gridItem) => {
          const bounds = [gridItem.bounds[0], gridItem.bounds[1]];
          const color = gridItem.being_processed ? "#FFFF00" : gridItem.color;
          this.createRectangle(bounds, color, gridItem);
          this.addGridItemMarker(gridItem);
        });
      });
    });
  }

  addGridItemMarker(gridItem) {
    const textIcon = L.divIcon({
      className: 'average-text',
      html: gridItem.average_position > 20 ? "20+" : gridItem.average_position,
      iconSize: [20, 20],
    });

    L.marker(gridItem.center, { icon: textIcon }).addTo(this.map);
  }

  loadGridData(callback) {
    $.ajax({
      url: $("#map").data("grid-url"),
      data: $("#map").data("grid-params"),
      success: callback,
    });
  }

  createRectangle(bounds, color, gridItem) {
    const options = {
      color: gridItem.color,
      weight: 0.5,
      fillColor: gridItem.color,
      fillOpacity: 0.3,
    };

    const rectangle = L.rectangle(bounds, options).addTo(this.map);
    rectangle.on('click', () => this.onRectangleClick(gridItem));
  }

  onRectangleClick(gridItem) {
    $.ajax({
      url: $("#map").data("cell-infos-url"),
      data: { bounds: gridItem.bounds },
      success: (data) => {} // Abstract method to be defined in subclasses like AnalysisShow or CampaignActionMap
    });
  }

  bindActionMarkers() {
    this.getActionsData((jsonData) => {
      jsonData.forEach((item) => {
        this.addActionMarker(item);
      });

      this.bindMarkerClickEvents();
    });
  }

  bindMarkerClickEvents() {
  }

  addActionMarker(item) {
    const customIcon = L.divIcon({
      className: 'custom-marker d-none',
      html: `<div data-id="${item.id}" class="marker-score">${item.is_cell_check ? "C" + item.score : item.score}</div>`,
      iconSize: [30, 30],
      iconAnchor: [15, 15],
      data: item.id
    });

    const marker = L.marker([item.lat, item.lng], { icon: customIcon }).addTo(this.map);
    marker._icon.style.backgroundColor = item.color;
  }

  getActionsData(callback) {
    $.ajax({
      url: $("#map").data("url"),
      data: $("#map").data("grid-params"),
      success: callback,
    });
  }

  bindUIEvents() {
    this.bindPingResultsCheckbox();
  }

  bindPingResultsCheckbox() {
    $("#ping_results_checkbox").prop("checked", false).on("change", function () {
      $(".custom-marker").toggleClass("d-none", !$(this).is(":checked"));
    });
  }

  startAutoRefresh() {
    setInterval(() => {
      this.refreshMapData();
    }, 10000); // 10 seconds
  }

  refreshMapData() {
    // clear map data
    this.map.eachLayer((layer) => {
      if (layer instanceof L.Rectangle) {
        this.map.removeLayer(layer);
      }
    });
    this.bindGridData();
    this.bindActionMarkers();
  }
}
